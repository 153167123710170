import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import { css } from "@emotion/core"

export default (props) => {

    return (
        <Layout>
            <div css={css`
                @media only screen and (max-width: 26.5625em) {
                    background-image: unset;
                }
                @media only screen and (max-width: 20em) {
                    padding: 0;
                }
            `} className="home">
                <div style={{ display: 'flex', alignItems: 'center' }} className="layout-container">
                    <div style={{ flex: '0 0 60%', marginLeft: 'auto' }} css={css`
                        @media only screen and (max-width: 26.5625em) {
                            flex: 1 !important;
                        }
                    `} className="content">
                        <div className="home__container">
                            <Link to="/about" css={css`
                                text-decoration: none;
                                cursor: pointer;
                            `} className="logo">
                                <span style={{ marginLeft: '-8rem' }}>c</span>
                                <span style={{ marginLeft: '0rem' }}>o</span>
                                <span>l</span>
                                <span style={{ marginLeft: '-4rem' }}>u</span>
                                <span style={{ marginLeft: '-4rem' }}>m</span>
                                <span style={{ marginLeft: '-8rem' }}>n</span>
                                <span style={{ marginLeft: '-8rem' }}>a</span>
                            </Link>
                        </div>
                        <div className="home__links">
                            <div css={css`
                                @media only screen and (max-width: 26.5625em) {
                                    flex: 1 !important;
                                }
                            `} className="home__links-container">
                                <ul>
                                    <Link state={{ prevPath: props.location.pathname }} style={{ marginTop: '-14rem', marginRight: '-2rem' }} to="/muralista">
                                        <span>Muralista</span>
                                        <div style={{ marginLeft: '1rem', flex: 1 }} className="line" />
                                    </Link>
                                </ul>
                                <ul style={{ flex: '0 0 43%' }}>
                                    <Link state={{ prevPath: props.location.pathname }} style={{ top: '-15rem' }} to="/artech-plaza">
                                        <div style={{ marginRight: '1rem', flex: 1 }} className="line" />
                                        <span>Artech Plaza</span>
                                    </Link>
                                    <div css={css`
                                        @media only screen and (max-width: 26.5625em) {
                                            display: flex !important;
                                        }
                                    `} style={{ marginTop: '9rem', position: 'relative' }} className="int-cont">
                                        <div style={{ width: '2rem', height: '2px', backgroundColor: '#333' }} css={css`
                                            @media only screen and (max-width: 26.5625em) {
                                                display: flex !important;
                                            }
                                        `} className="line-hor" />
                                        <div className="int-cont__container">
                                            <div className="line-left" />
                                            <Link state={{ prevPath: props.location.pathname }} style={{ margin: 0, marginBottom: '4rem' }} to="/maroma">
                                                <div style={{ marginRight: '1rem' }} className="line" />
                                                <span>Maroma</span>
                                            </Link>
                                            <Link css={css`
                                                @media only screen and (max-width: 26.5625em) {
                                                    margin-top: -1rem !important;
                                                }
                                            `} state={{ prevPath: props.location.pathname }} style={{ margin: 0 }} to="/feria-maroma">
                                                <div style={{ marginRight: '1rem' }} className="line" />
                                                <span>Feria Maroma</span>
                                            </Link>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
